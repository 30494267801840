import moment from 'moment';

var formatters = {
    diffForHumans: (date) => {
        return moment(date).fromNow();
    },
    longDate: (date) => {
        let d = new Date(date);
        return (
            d.toLocaleDateString("en-UK") +
            " " +
            d.toLocaleTimeString("en-UK")
        );
    },
    fullDate: (date) => {
        return date ? moment(String(date)).format("DD MMMM YY, HH:mm") : "";
    },
    shortDate: (date) => {
        return moment(date).format("DD MMM");
    },
    date: (date) => {
        return (new Date(date)).toLocaleDateString("en-UK");
    },
    hour: (date) => {
        return (new Date(date)).toLocaleTimeString("en-UK");
    },
    shortHour: (date) => {
        let d = (new Date(date))
        var hours = d.getHours();
        var minutes = d.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    },
    money: (amount) => {
        if (!amount) {
            amount = 0;
        }
        return "£" + (Math.round(amount) / 100).toFixed(2);
    },
    moneyWithoutCurrency: (amount) => {
        if (!amount) {
            amount = 0;
        }
        return (Math.round(amount) / 100).toFixed(2);
    },
    quantity: (value) => {
        return value;
    },
    percentage: (value) => {
        return parseFloat(value).toFixed(0) + '%';
    },
    decimal: (value) => {
        return parseFloat(value).toFixed(2);
    },
    integer: (value) => {
        return parseFloat(value).toFixed(0);
    },
    humanInterval: (intervalInSeconds) => {
        let duration = moment.duration(intervalInSeconds, 'seconds');
        let years = duration.years();
        let months = duration.months();
        let days = duration.days();
        let hours = duration.hours();
        let minutes = duration.minutes();
        let seconds = duration.seconds();
        let message = "";
        if (years > 0) {
            message += `${years} years, `;
        }
        if (months > 0) {
            message += `${months} months, `;
        }
        if (days == 1) {
            message += `${days} day, `;
        }
        if (days > 1) {
            message += `${days} days, `;
        }
        if (hours == 1) {
            message += `${hours} hour, `;
        }
        if (hours > 1) {
            message += `${hours} hours, `;
        }
        if (minutes == 1) {
            message += `${minutes} minute, `;
        }
        if (minutes > 1) {
            message += `${minutes} minutes, `;
        }
        if (seconds == 1) {
            message += `${seconds} second `;
        }
        if (seconds > 1) {
            message += `${seconds} seconds `;
        }
        return message ? message : "< 1 second";
    },
};

export const formatValue = (value, type) => {
    if (!formatters[type]) {
        return value;
    }
    return formatters[type](value);
}
