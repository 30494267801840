<template>
  <div class="content">
    <h4>Simulations</h4>
    <div class="background">
      <div class="create">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button class="vave-btn btn-outline-green" @click="getSimulations()">
              <i class="fa fa-sync pe-1"></i> Refresh
            </button>
          </div>
          <div class="col-auto">
            <router-link :to="{ name: 'automation' }" class="mb-4 vave-btn">BACK</router-link>
          </div>
        </div>

        <div class="row" v-if="automation">
          <div class="col-6">
            <table class="table table-bordered">
              <thead>
                <th colspan="2">Automation</th>
              </thead>
              <tbody>
                <tr>
                  <td style="width: 200px;">Model</td>
                  <td>{{automation.model}}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{{automation.description}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <div class="row">
              <div class="col">
                Interval:
                <select v-model="interval" class="form-select">
                  <option value="60">Every Hour</option>
                  <option value="1440">Every Day</option>
                </select>
              </div>
              <div class="col">
                From:
                <br />
                <input class="form form-control" v-model="from" type="datetime-local" />
              </div>
              <div class="col">
                To:
                <br />
                <input class="form form-control" v-model="to" type="datetime-local" />
              </div>
              <div class="col">
                <br />
                <button class="vave-btn btn-green" @click="createSimulation()">Simulate</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table">
        <table class="table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Interval</th>
              <th>From</th>
              <th>To</th>
              <th>Runs</th>
              <th>Status</th>
              <th>Models</th>
              <th>Avg. Models/Run</th>
              <th>Unique Models</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="simulation in simulationsList.data" :key="simulation.id">
              <td>{{simulation.id}}</td>
              <td>{{simulation.interval}}</td>
              <td>{{formatValue(simulation.from , 'longDate')}}</td>
              <td>{{formatValue(simulation.to , 'longDate')}}</td>
              <td class="align-middle">
                <div class="progress" style="height: 20px;">
                  <div
                    class="progress-bar"
                    :class="{
                      'bg-secondary': simulation.status == 'pending',
                      'bg-primary': simulation.status == 'in_progress',
                      'bg-success': simulation.status == 'succeeded',
                      'bg-danger': simulation.status == 'failed',
                  }"
                    role="progressbar"
                    :style="'width: '+((simulation.completed_runs/simulation.runs)*100)+'%'"
                    :aria-valuenow="simulation.completed_runs"
                    aria-valuemin="0"
                    :aria-valuemax="simulation.runs"
                  >{{simulation.completed_runs}}/{{simulation.runs}}</div>
                </div>
              </td>
              <td class="align-middle">
                <span
                  class="badge"
                  :class="{
                  'bg-secondary': simulation.status == 'pending',
                  'bg-primary': simulation.status == 'in_progress',
                  'bg-success': simulation.status == 'succeeded',
                  'bg-danger': simulation.status == 'failed',
                }"
                  style="position:relative;bottom:2px;"
                >{{simulation.status}}</span>
              </td>
              <td>{{simulation.total_actions_count}}</td>
              <td>{{formatValue(simulation.total_actions_count/simulation.runs, 'decimal')}}</td>
              <td>{{simulation.unique_actions_count}}</td>
              <td>
                <div class="d-flex">
                  <div class="vave-btn btn-red" @click="deleteSimulation(simulation.id)">
                    <i class="fa fa-trash"></i>
                  </div>
                  <router-link v-if="simulation.status == 'succeeded'" :to="{ name: 'automationsSimulationsShow', id: simulation.id, params: { simulation: simulation.id} }">
                    <div class="vave-btn btn-outline-blue ms-2" title="show job output">
                      <i class="fa fa-eye"></i>
                    </div>
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination class="paginator" :limit="4" align="center" :data="simulationsList" @pagination-change-page="getSimulations"></pagination>
    </div>
  </div>
</template>

<script>
import MqttClient from "./../mqtt.js";
import axios from "../http.js";
import pagination from "laravel-vue-pagination";
import EventBus from "./../bus.js";
import { formatValue } from "../formatters";

export default {
  data() {
    return {
      automation: null,
      output: null,
      simulationsList: {},
      interval: 1440,
      from: "2022-06-13T00:00:00",
      to: "2022-06-16T00:00:00",
    };
  },

  components: { pagination },
  beforeDestroy() {
    EventBus.$off("globus/vaveproxy/automations/simulations/#", this.getSimulations);
  },
  mounted() {
    this.from = new Date().toISOString().substr(0, 16);
    this.to = new Date().toISOString().substr(0, 16);
    if (Object.keys(this.simulationsList).length <= 0) {
      this.getSimulations();
    }
    let topic = "globus/vaveproxy/automations/simulations/#";
    MqttClient.subscribe(topic);
    EventBus.$on(topic, this.getSimulations);

    // Get the automation details
    axios
      .get("/api/admin/automations/" + this.$route.params.id)
      .then((response) => {
        if (response.data.data) {
          this.automation = response.data.data;
        }
      });
  },
  methods: {
    formatValue,
    deleteSimulation(id) {
      if (!confirm("do you want to delete the simulation? ")) {
        return;
      }
      axios
        .delete(
          "/api/admin/automations/" +
            this.$route.params.id +
            "/simulations/" +
            id
        )
        .then((res) => {
          this.getSimulations();
          if (!res.statusCode == 200) {
            this.$toast.error("There was a problem");
          } else {
            this.$toast.success("Simulation deleted");
          }
        });
    },
    currentDateTime() {
      const current = new Date();
      const date =
        current.getFullYear() +
        "-" +
        (current.getMonth() + 1) +
        "-" +
        current.getDate();
      const time = current.getHours() + ":" + current.getMinutes();
      const dateTime = date + "T" + time;
      // alert(dateTime);
      return dateTime;
    },
    showOutput(id) {
      this.output = null;
      axios
        .get(
          "/api/admin/automations/" +
            this.$route.params.id +
            "/simulations/" +
            id
        )
        .then((res) => {
          if (res.data) {
            this.output = res.data.data.results;
          }
        });
    },
    getSimulations(page = 1, perPage = 10) {
      // this.simulationsList = {};
      axios
        .get(
          "/api/admin/automations/" +
            this.$route.params.id +
            "/simulations?page=" +
            page +
            "&per_page=" +
            perPage
        )
        .then((response) => {
          if (response.data.data) {
            this.simulationsList = response.data;
          }
        });
    },
    createSimulation() {
      if (!confirm("do you want to start the simulation?")) {
        return;
      }
      this.pushJob(this.command);
    },
    pushJob() {
      axios
        .post(
          "/api/admin/automations/" + this.$route.params.id + "/simulations",
          {
            from: this.from,
            to: this.to,
            interval: this.interval,
          }
        )
        .then(
          () => {
            this.getSimulations();
          },
          (e) => {
            this.$toast.error(e.response.data.message);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
.create {
  padding-bottom: 20px;
}
</style>